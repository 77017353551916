@font-face {
  font-family: "plus_jakarta_displaybold";
  src: url("../fonts/plusjakartadisplay-bold-webfont.woff2") format("woff2"),
    url("../fonts/plusjakartadisplay-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "plus_jakarta_displayregular";
  src: url("../fonts/plusjakartadisplay-regular-webfont.woff2") format("woff2"),
    url("../fonts/plusjakartadisplay-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold";
  src: url("../fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("../fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("../fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("../fonts/poppins-semibold-webfont.woff2") format("woff2"),
    url("../fonts/poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "rubikRegular";
  src: url("../fonts/Rubik-Regular.woff2") format("woff2"),
    url("../fonts/Rubik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "rubikBold";
  src: url("../fonts/Rubik-Bold.woff2") format("woff2"),
    url("../fonts/Rubik-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "rubikExtraBold";
  src: url("../fonts/Rubik-ExtraBold.woff2") format("woff2"),
    url("../fonts/Rubik-ExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "rubikSemibold";
  src: url("../fonts/Rubik-SemiBold.woff2") format("woff2"),
    url("../fonts/Rubik-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sorabold";
  src: url("../fonts/sora-bold-webfont.woff2") format("woff2"),
    url("../fonts/sora-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "soraregular";
  src: url("../fonts/sora-regular-webfont.woff2") format("woff2"),
    url("../fonts/sora-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "soralight";
  src: url("../fonts/Sora-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sorasemibold";
  src: url("../fonts/sora-semibold-webfont.woff2") format("woff2"),
    url("../fonts/sora-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ranyregular";
  src: url("../fonts/rany-webfont.woff2") format("woff2"),
    url("../fonts/rany-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ranybold";
  src: url("../fonts/rany-bold-webfont.woff2") format("woff2"),
    url("../fonts/rany-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ranymedium";
  src: url("../fonts/rany-medium-webfont.woff2") format("woff2"),
    url("../fonts/rany-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "soraregular", sans-serif;
  font-weight: normal;
  font-size: 14px;
  height: 100%;
  /* background-image: url('../images/bg.jpg'); */
  background-color: #050d24;
  background-size: cover;
}
.site-wrapper {
  background-color: #050d2400 !important;
  color: #fff !important;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      hsla(0, 0%, 100%, 0.048) 25%,
      hsla(0, 0%, 100%, 0.048) 26%,
      transparent 27%,
      transparent 74%,
      hsla(0, 0%, 100%, 0.048) 75%,
      hsla(0, 0%, 100%, 0.048) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      hsla(0, 0%, 100%, 0.048) 25%,
      hsla(0, 0%, 100%, 0.048) 26%,
      transparent 27%,
      transparent 74%,
      hsla(0, 0%, 100%, 0.048) 75%,
      hsla(0, 0%, 100%, 0.048) 76%,
      transparent 77%,
      transparent
    );
  height: 100%;
  background-size: 190px 120px;
  position: relative;
}
.dropdown-menu.serviceDropdown {
  width: 600px;
  background-color: #020e1a;
  padding: 30px 30px 30px 30px;
  box-shadow: 1px 1px 10px #00000085;
}

.dropdown-menu.serviceDropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 0px;
  clear: both;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  font-family: "soraregular";
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  margin-bottom: 12px;
  cursor: pointer;
}
.dropdown-menu.serviceDropdown .dropdown-item img {
  margin-right: 15px;
  width: 50px;
  height: 50px;
}
.dropdown-menu.serviceDropdown li {
  width: 50%;
}
.nav-item.dropdown:hover .dropdown-menu.serviceDropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.navbar-nav li.nav-item:hover a.nav-link,
.dropdown-menu.serviceDropdown li:hover a.dropdown-item {
  color: #ffb844 !important;
}
.navbar-nav li.nav-item.active > a {
  color: #ffb844 !important;
  position: relative;
}
.navbar-nav li.nav-item.active > a::before {
  content: " ";
  width: calc(100% - 1rem);
  height: 2px;
  position: absolute;
  top: 100%;
  left: 0.5rem;
  background: linear-gradient(45deg, #fb53ba, #ff7f41);
}
p {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  font-family: "soraregular", sans-serif;
  margin: 0px auto 40px;
}
h1 {
  font-family: "ranybold";
  color: #fff;
  font-size: 70px;
  text-align: center;
  font-weight: 900;
  line-height: 70px;
  margin-bottom: 20px;
}
h1.bannerHeading {
  font-family: "ranybold";
  color: #fff;
  font-size: 70px;
  text-align: center;
  font-weight: 900;
  line-height: 70px;
  margin-bottom: 20px;
  margin-top: 80px;
}
h2.subHeading {
  font-family: "ranybold";
  color: #fff;
  font-size: 70px;
  text-align: center;
  font-weight: 900;
  line-height: 70px;
  margin-bottom: 20px;
}
h2.gradientOrange {
  background: -webkit-linear-gradient(264deg, #ff7f41, #ffc845, #fb53ba);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

h2.mainHeading {
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  width: auto;
  display: inline-block;
  margin: 50px auto 20px;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}
h2.mainHeading:after {
  content: " ";
  width: 100%;
  height: 3px;
  position: absolute;
  top: 100%;
  left: 0;
}
h2.mainHeading::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  top: 100%;
  left: 0;
  border-left: 10px solid #000;
  border-right: 10px solid #000;
  z-index: 1;
  animation: animborder 4s linear infinite;
}
h1.mainHeading {
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  width: auto;
  display: inline-block;
  margin: 50px auto 20px;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1.mainHeading:after {
  content: " ";
  width: 100%;
  height: 3px;
  position: absolute;
  top: 100%;
  left: 0;
}
.varient1 {
  background: linear-gradient(90deg, #9962cb, #fb53ba, #ff7f41, #ffc845);
}
.varient1:after {
  background: linear-gradient(45deg, #ffc845, #8edd65);
}
.varient2 {
  background: linear-gradient(90deg, #fb53ba, #5869f3, #67d2df);
}
.varient2:after {
  background: linear-gradient(45deg, #8edd65, #ffc845);
}
.varient3 {
  background: linear-gradient(90deg, #fb53ba, #ff8841, #ffc845, #fc5aa7);
}
.varient3:after {
  background: linear-gradient(45deg, #5869f3, #47d7ac);
}
.varient4 {
  background: linear-gradient(90deg, #8edd65, #67d2df, #5869f3);
}
.varient4:after {
  background: linear-gradient(45deg, #645275, #fb53ba, #ff7f41, #ffc845);
}
.varient5 {
  background: linear-gradient(90deg, #fb53ba, #9b1af7, #67d2df);
}
.varient5:after {
  background: linear-gradient(45deg, #9962cb, #fb53ba, #ff7f41, #ffc845);
}
.varient6 {
  background: linear-gradient(90deg, #ffc845, #ff7f41);
}
.varient6:after {
  background: linear-gradient(45deg, #8edd65, #47d7ac);
}

.gradientOrange1 {
  background: -webkit-linear-gradient(321deg, #fb53ba, #9b1af7, #67d2df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientOrange2 {
  background: -webkit-linear-gradient(150deg, #fb53ba, #9b1af7, #67d2df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  position: relative;
  font-size: 50px;
  display: inline-block;
  font-family: "ranybold";
  color: #fff;
}

h2.gradientOrange {
  background: linear-gradient(
    90deg,
    #9962cb,
    #fb53ba 20%,
    #ff7f41 52%,
    #ffc845 70%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

h2 span:before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 4px;
  border-radius: 2px;
  background: linear-gradient(108deg, #8edd65, #ffc845);
}
.form-control:focus {
  color: #ffffff !important;
  background-color: #00000036 !important;
  border-color: #ffc24c !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 199 69 / 58%);
}
.modal-backdrop.show {
  opacity: 0.8;
}

#talkteam .btn-close {
  box-sizing: content-box;
  width: unset;
  height: unset;
  padding: 6px 10px;
  color: #fff;
  border: 0;
  border-radius: 50px;
  opacity: 0.5;
  background: #000;
  position: absolute;
  top: 10px;
  right: 10px;
}

#talkteam .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 17px;
  outline: 0;
  overflow: hidden;
  max-height: 680px;
}

#talkteam .modal-content h2 {
  font-size: 24px;
  font-family: "plus_jakarta_displaybold";
  margin: 40px 0 12px 0px;
  color: #161616;
}

#talkteam .modal-content .img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
}

#talkteam .formContainer {
  padding: 0px 40px;
}

#talkteam .modal-content p {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #8b8b8b;
  margin: 0px auto 20px;
}

#talkteam .modal-content form .form-floating > .form-control {
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-family: "plus_jakarta_displayregular";
  border-radius: 8px !important;
  border: 1px solid #162f5d;
}

#talkteam .modal-content form .form-floating > label {
  font-size: 12px;
  padding-top: 16px;
  font-family: "plus_jakarta_displayregular";
  color: #0e1216;
}

#talkteam .modal-content form .form-floating {
  margin-bottom: 5px;
}

#talkteam h4 {
  font-size: 20px;
  font-family: "plus_jakarta_displaybold";
  margin: 15px 0 6px 0px;
  color: rgba(0, 0, 0, 0.6);
}

#talkteam .form-check label {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #545454;
}

#talkteam .checkListItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

#talkteam .checkListItems .form-check {
  width: 50%;
}

#talkteam button.btnfull {
  font-size: 16px;
  border-radius: 5px;
  background-color: #feeab4;
  color: #fb972f;
  min-height: 44px !important;
  transition: all 0.25s ease-in-out;
  border: 1px solid #fb972f;
  display: flex !important;
  align-content: center;
  justify-content: center;
}

#talkteam button.btnfull:hover {
  background-color: #fdbb09;
  background-image: linear-gradient(#fdbb09, #fb972f);
  color: #fff;
  box-shadow: 2px 7px 17px #fb972f;
  box-shadow: 0px 24px 17px -14px #e8922570;
  transition: all 0.25s ease-in-out;
}

#talkteam button.btnfull i {
  margin-top: 6px;
}

#discountModal .btn-close {
  box-sizing: content-box;
  width: unset;
  height: unset;
  padding: 6px 10px;
  color: #fff;
  border: 0;
  border-radius: 50px;
  opacity: 0.5;
  background: #000;
  position: absolute;
  top: 10px;
  right: 10px;
}

#discountModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 17px;
  outline: 0;
  overflow: hidden;
  max-height: 680px;
}

#discountModal .modal-content h2 {
  font-size: 24px;
  font-family: "plus_jakarta_displaybold";
  color: #161616;
}

#discountModal .modal-content .img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
}

#discountModal .formContainer {
  padding: 0px 40px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

#discountModal .modal-content p {
  font-family: "plus_jakarta_displayregular";
  font-size: 16px;
  font-weight: 400;
  color: #840b55;
}

#discountModal .modal-content form .form-floating > .form-control {
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-family: "plus_jakarta_displayregular";
  border-radius: 8px !important;
  border: 1px solid #162f5d;
}

#discountModal .modal-content form .form-floating > label {
  font-size: 12px;
  padding-top: 16px;
  font-family: "plus_jakarta_displayregular";
  color: #0e1216;
}

#discountModal .modal-content form .form-floating {
  margin-bottom: 10px;
}

#discountModal h4 {
  font-size: 20px;
  font-family: "plus_jakarta_displaybold";
  margin: 20px 0 12px 0px;
  color: rgba(0, 0, 0, 0.6);
}

#discountModal .form-check label {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #545454;
}

#discountModal .checkListItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

#discountModal .checkListItems .form-check {
  width: 50%;
}

#discountModal button.btnfull {
  font-size: 16px;
  border-radius: 5px;
  background-color: #feeab4;
  color: #fb972f;
  min-height: 44px !important;
  transition: all 0.25s ease-in-out;
  border: 1px solid #fb972f;
  display: flex !important;
  align-content: center;
  justify-content: center;
}

#discountModal button.btnfull:hover {
  background-color: #fdbb09;
  background-image: linear-gradient(#fdbb09, #fb972f);
  color: #fff;
  box-shadow: 2px 7px 17px #fb972f;
  box-shadow: 0px 24px 17px -14px #e8922570;
  transition: all 0.25s ease-in-out;
}

#discountModal button.btnfull i {
  margin-top: 6px;
}

.price-container ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: -6px;
}

.price-container ul li {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0px;
}

.price-container ul li span {
  font-family: "soralight";
  font-size: 12px;
}

.callUsSection {
  background: linear-gradient(90deg, #f4364c, #840b55);
  height: 40px;
  display: flex;
  align-items: center;
}
.callUsSection ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.callUsSection ul li {
  padding-left: 40px;
}
.callUsSection ul li a {
  font-size: 14px;
}
.callUsSection ul li a img {
  padding-left: 40px;
}

.callUsSection span:first-child a {
  padding-right: 40px;
}

.callUsSection img {
  padding-right: 11px;
}
/* 
.callUsSection address {
  height: 50px;
} */

a {
  color: #fff !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  color: #fff !important;
}

button.btn,
a.btn {
  padding: 10px 14px;
  font-size: 14px;
  font-family: "plus_jakarta_displayregular";
  background-color: transparent;
  color: #fff;
  background-clip: padding-box;
  border: 1px solid #fb53ba;
  transition: all 0.25s ease-in-out;
}

button.btn:hover,
a.btn:hover {
  box-shadow: 0px 5px 29px -8px #f1354c !important;
  color: #fff !important;
  background-color: #f0354d;
  background-image: linear-gradient(#f4364c, #840b55);
  transition: all 0.25s ease-in-out;
}

.border {
  font-size: 1.6rem;
  display: grid;
  place-items: center;
  min-height: 200px;
  border: 8px solid;
  padding: 1rem;
}

.btnGradient {
  color: #fff;
  width: 260px;
  border: none;
  cursor: pointer;
  margin: 0 auto 20px auto;
  display: flex;
  padding: 5px 20px;
  z-index: 1;
  position: relative;
  font-size: 16px;
  min-width: 260px;
  background: linear-gradient(286deg, #ff7f41, #ffc845, #fb53ba);
  min-height: 58px;
  align-items: center;
  font-weight: 400;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.btnGradient img {
  margin-right: 13px;
  width: 35px;
}

.btnGradient:before {
  content: " ";
  top: 1px;
  left: 1px;
  width: 256px;
  height: 54px;
  z-index: -1;
  position: absolute;
  animation: animatedgradient 10s ease alternate infinite;
  background: #050d24;
  border-radius: 6px;
  -webkit-animation: animatedgradient 10s ease alternate infinite;
  transition: all 0.25s ease-in-out;
}

.btnGradient:hover {
  color: #fff;
  box-shadow: -1px 15px 35px #ff804178 !important;
  transition: all 0.25s ease-in-out;
}
.btnGradient:hover:before {
  background: #000;
  transition: all 0.25s ease-in-out;
}

.btnGradientOne {
  color: #000 !important;
  width: 260px;
  border: none !important;
  cursor: pointer;
  height: 58px;
  margin: 0 auto;
  display: flex;
  padding: 10px 40px !important;
  font-size: 16px !important;
  background: linear-gradient(286deg, #ff7f41, #ffc845, #fb53ba);
  align-items: center;
  font-weight: 400;
  border-radius: 6px;
  justify-content: center;
  text-decoration: none;
}

.btnGradientOne:hover {
  box-shadow: 0px 4px 11px #ff804180 !important;
}

.mt-77 {
  margin-top: 77px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mb-53 {
  margin-bottom: 53px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

nav {
  background-color: #161a1a !important;
}

nav .nav-item {
  margin-right: 30px;
}

nav .nav-item:last-child {
  margin-right: 0;
}

.top1 {
  top: 68% !important;
  left: 38% !important;
}

.top2 {
  top: 60% !important;
  left: 56% !important;
}

.top3 {
  top: 63% !important;
  left: 50% !important;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.navbar-nav {
  align-items: center;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-size: 14px;
  font-family: "plus_jakarta_displayregular";
  cursor: pointer;
}
header#header.fixed-top {
  transition: all 0.35s ease-in-out;
}
header#header.stickyHeader {
  box-shadow: 0px 6px 30px -10px #00000082;
  top: -36px;
  transition: all 0.35s ease-in-out;
}
.InnerPageTitle {
  padding-top: 150px;
}
.innerPageTemp .priceSection {
  padding-top: 150px;
}
.innerPageTemp .PortfolioSectionScreen {
  padding-top: 0px;
}
@media (width: 768px) {
	.innerPageTemp .PortfolioSectionScreen {
	  width: 100%;
	}
  }
.banner {
  padding-top: 120px;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  z-index: 1;
}

.banner:before {
  background-image: url("../images/left.png");
  content: "";
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  z-index: -2;
  left: -110px;
  position: absolute;
  width: 100%;
  top: 260px;
  max-width: 600px;
  animation: bouncing 5s infinite linear;
}

.banner:after {
  background-image: url("../images/right.png");
  content: "";
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  z-index: -2;
  right: -110px;
  position: absolute;
  width: 100%;
  top: 260px;
  max-width: 600px;
  animation: bouncing 5s infinite linear;
}

.PrtfolioGrid .grid-wrapper .box {
  background-color: transparent;
  color: #fff;
  border-radius: 0;
  padding: 0;
  position: relative;
  border: 1px solid transparent;
  width: 32%;
  margin-bottom: 1%;
}

.PrtfolioGrid .grid-wrapper .box .video:before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000066;
  background-image: linear-gradient(#98387a73, #ea235cad);
  z-index: 1;
  position: absolute;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.PrtfolioGrid .grid-wrapper .box img.img-fluid {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  transform: scale(1);
  border-radius: 15px;
  z-index: 0;
  position: relative;
  transition: all 0.25s ease-in-out;
}
.PrtfolioGrid .grid-wrapper .box:hover img.img-fluid {
  transform: scale(1.1);
  transition: all 0.25s ease-in-out;
}
.VideoSection {
  margin-bottom: 80px;
  min-height: 600px;
  display: block;
}

.videoIframe {
  overflow: hidden;
}

.videoIframe .iframeV {
  background: linear-gradient(
    90deg,
    #9962cb,
    #fb53ba 20%,
    #ff7f41 52%,
    #ffc845 70%
  );
  padding: 5px 5px 1px 5px;
  border-radius: 15px;
  max-width: 80%;
  margin: 0px auto;
}

.videoIframe .container {
  max-width: 1364px;
}

.videoIframe .container::before {
  background-image: url(../images/star.svg);
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 80%;
  z-index: 1;
  left: 5%;
  animation: blinks 3s infinite linear;
}

.videoIframe .container::after {
  background-image: url(../images/ball.png);
  content: "";
  width: 123px;
  height: 123px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 30%;
  z-index: 1;
  right: -54px;
  opacity: 0.5;
  overflow: hidden;
  animation: rotation 4s infinite linear;
}

.videoIframe iframe {
  min-height: 500px;
  border-radius: 15px;
  background-color: #000;
}

/* .VideoSection {
	margin-top: 152px;
} */
@media (min-width: 768px) {
  .navbars {
    width: 50%;
  }
}

.navbar-dark {
  background-color: #161a1a5c !important;
  height: 80px;
  transition: all 0.35s ease-in-out;
}
.stickyHeader .navbar-dark {
  background-color: #020e1a !important;
  height: 70px;
  transition: all 0.35s ease-in-out;
}
.navbar-brand img {
  width: 170px;
}
.PackageContainerSlider {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 16px;
	margin-top: 30px;
}

.PackageContainerSlider .price-container {
	margin-bottom: 50px;
    flex: 1 1 1 1  30%; /* Takes about 45% of row width for two cards per row */
    max-width: 30%;
}
@media (max-width: 1024px) {
    .PackageContainerSlider .price-container {
        flex: 1 1 45%; /* Stacks cards vertically on smaller screens */
        max-width: 30%;
    }
}
@media (max-width: 1024px) {
  .PackageContainerSlider {
    flex-wrap: wrap;
  }
}


@media (max-width: 768px) {
    .PackageContainerSlider .price-container {
        flex: 1 1 45%; /* Stacks cards vertically on smaller screens */
        max-width: 45%;
    }
}
.price-container {
  padding: 28px 30px;
  padding-top: 60px;
  border-radius: 20px;
  border: 1px solid transparent;
  margin: 0 12px;
  color: #fff;
  margin-bottom: 60px;
  min-height: 500px;
  max-height: 500px;
  /* height: 470px; */
  max-width: 300px;
  min-width: 300px;
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #949cbe;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: inset 0 0 15px 18px #ffffff3d;
  transition: all 0.25s ease-in-out;
}

.price-container:hover {
  background: linear-gradient(135deg, #5869f3, #9962cb);
  transition: all 0.25s ease-in-out;
}
.price-container:hover:before {
  background-image: url(../images/active-obj.png);
  transition: all 0.2s ease-in-out;
}
.price-container::before {
  content: "";
  width: 100%;
  height: 220px;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  background-image: url(../images/unactive-obj.png);
  background-size: 110%;
  z-index: 0;
  background-position: top center;
  transition: all 0.25s ease-in-out;
}
.priceSection .tab-content .tab-pane {
  padding-top: 120px;
}
.price-container p {
  font-size: 12px !important;
}

.price-container.active {
  background-color: transparent;
}

.price-container .bi {
  color: #fff;
}

.price-container button,
.price-container a {
  background-color: #fff;
  color: #000 !important;
}

.price-container button:hover,
.price-container a:hover {
  color: #fff;
}

h4 {
  font-size: 24px;
  font-family: "plus_jakarta_displayregular";
}

h3 {
  font-size: 38px;
  font-family: "plus_jakarta_displaybold";
}

.mt-80 {
  margin-top: 80px;
}

.h3Bar {
  color: #fff;
  font-size: 72px;
  font-family: "ranyregular";
  position: relative;
}

.h3Bar span {
  position: relative;
}

.h3Bar span:before {
  content: "";
  display: inline-block;
  width: 122px;
  height: 4px;
  margin-bottom: 20px;
  margin-right: 35px;
  border-radius: 2px;
  background: linear-gradient(89deg, #8edd65, #47d7ac);
}

.h3Bar span:after {
  content: "";
  display: inline-block;
  width: 122px;
  height: 4px;
  margin-bottom: 20px;
  margin-left: 35px;
  border-radius: 2px;
  background: linear-gradient(89deg, #8edd65, #47d7ac);
}


header a.nav-link.active:before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(#9962cb, #fb53ba, #ff7f41, #ffc845);
}

.mb-35 {
  margin-bottom: 35px;
}

.mt-200 {
  margin-top: 200px;
}

header a.nav-link.active {
  position: relative;
}

.modalbutton {
  font-size: 12px;
  font-family: "plus_jakarta_displayregular";
  padding-top: 17px;
  padding-bottom: 17px;
}

sup {
  top: -1.5em;
  font-size: 20px;
  font-family: "plus_jakarta_displaybold";
}

.PrtfolioGrid .grid-wrapper .box img.img-fluid {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
}

.PrtfolioGrid .grid-wrapper .box:hover .video::before {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.VideoSection .video:hover .middle,
.ChooseSection .video:hover .middle {
  opacity: 1;
  cursor: pointer;
}

.video .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.8);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px !important;
  outline: 0;
  z-index: 1;
  padding: 30px;
  box-shadow: 0 0 16px 0 #0000003d;
}

.video .modal-content .btn-close {
  box-sizing: content-box;
  padding: 5px 9px;
  color: #fff;
  width: unset;
  height: unset;
  border: 0;
  border-radius: 100px;
  background-color: #fdbb09;
  background-image: linear-gradient(#fdbb09, #fb972f);
  opacity: 1;
  position: absolute;
  right: -10px;
  top: -10px;
}

iframe.iframevideo {
  min-height: 400px;
}

.VideoSection .video {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(white, #ffffff),
    linear-gradient(to left, #9962cb, #fb53ba, #ff7f41, #ffc845);
  border-radius: 20px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 4px;
  transition: all 0.35s ease-in-out;
}

.VideoSection .video .middle {
  z-index: 2;
}
.PrtfolioGrid .grid-wrapper {
  /* display: grid; */
  /* grid-gap: 12px 12px; */
  /* grid-template-columns: auto; */
  /* grid-template-rows: auto; */
  color: #444;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
/* .PrtfolioGrid .grid-wrapper .PrtfolioItem1 {
	grid-column: 1;
	grid-row: 1;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem2 {
	grid-column: 1;
	grid-row: 2;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem3 {
	grid-column: 2 / 4;
	grid-row: 1 / 3;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem4 {
	grid-column: 4 / 6;
	grid-row: 1;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem5 {
	grid-column: 4/6;
	grid-row: 2;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem6 {
	grid-column: 6;
	grid-row: 1;
}

.PrtfolioGrid .grid-wrapper .PrtfolioItem7 {
	grid-column: 6;
	grid-row: 2;
} */

.AboutSection {
  position: relative;
}

.AboutSection img {
  height: 120px;
}

.AboutSection h4 {
  font-family: "poppinsbold";
  margin-bottom: 12px;
  color: #fff;
}

.AboutSection .row p {
  font-size: 24px;
  font-family: "poppinsregular";
}

.AboutSection .container::before {
  background-image: url(../images/ball.png);
  content: "";
  width: 123px;
  height: 123px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 30%;
  z-index: 1;
  left: -54px;
  opacity: 0.5;
  overflow: hidden;
  animation: rotation 4s infinite linear;
}

.AboutSection .container::after {
  background-image: url(../images/ball.png);
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 50%;
  z-index: 1;
  right: 2%;
  overflow: hidden;
  animation: rotation 6s infinite linear;
}
.CommentsSection {
  height: 650px;
}
.ChooseSection {
  position: relative;
}

.ChooseSection .feature-card .video {
  position: relative;
}

.ChooseSection .feature-card .video:before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000066;
  z-index: 0;
  position: absolute;
  opacity: 0;
}

.ChooseSection .feature-card .video .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.8);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px !important;
  outline: 0;
  z-index: 1;
  padding: 30px;
  box-shadow: 0 0 16px 0 #0000003d;
}

.ChooseSection .feature-card .video .modal-content .btn-close {
  box-sizing: content-box;
  padding: 5px 9px;
  color: #fff;
  width: unset;
  height: unset;
  border: 0;
  border-radius: 100px;
  background-color: #fdbb09;
  background-image: linear-gradient(#fdbb09, #fb972f);
  opacity: 1;
  position: absolute;
  right: -10px;
  top: -10px;
}

.ChooseSection h4 {
  font-family: "ranybold";
  margin-bottom: 12px;
  color: #fff;
  font-size: 56px;
}

.ChooseSection .row p {
  font-size: 24px;
  font-family: "poppinsregular";
}

.ChooseSection .container::before {
  background-image: url(../images/rocket1.png);
  content: "";
  width: 606px;
  height: 606px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 84%;
  z-index: 1;
  left: -10%;
  overflow: hidden;
}

.ChooseSection .container::after {
  background-image: url(../images/earth.png);
  content: "";
  width: 210px;
  height: 210px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 50%;
  z-index: 1;
  right: 0%;
  overflow: hidden;
}

.AboutSection.feature .feature-card {
  height: 300px;
  align-items: center;
  padding: 0 50px;
  margin: 40px 0 0px 0 !important;
}

.AboutSection.feature p.number {
  font-size: 42px;
  font-family: "soralight";
  margin-bottom: 16px;
  min-height: 35px;
}

.AboutSection.feature h4 {
  font-size: 26px;
  font-family: "ranybold";
  margin-bottom: 22px;
}

.AboutSection.feature p {
  min-height: 140px;
  font-family: "soralight";
  font-size: 18px;
}

.AboutSection.feature img {
  height: auto;
  width: 200px;
  max-width: 200px;
}
.feature-card .feature-img {
  display: flex;
  align-items: center;
}
.ChooseSection.feature .feature-card {
  margin-bottom: 89px;
}

.ChooseSection.feature p.number {
  font-size: 42px;
  font-family: "soralight";
  margin-bottom: 16px;
  min-height: 35px;
}

.ChooseSection.feature h4 {
  font-size: 26px;
  font-family: "ranybold";
  margin-bottom: 22px;
}

.ChooseSection.feature p {
  min-height: 140px;
  font-family: "soralight";
  font-size: 20px;
  line-height: 40px;
}
.CompanySlider {
  height: 110px;
  display: block;
}
.CompanySlider img {
  height: 120px;
  padding: 0 40px;
}

/* .sliderAb {
	margin-top: 122px;
} */

.CommentsSection .sliderAb {
  margin-bottom: 70px;
}

.sliderAb .ratting {
  margin: 0 20px;
}
.sliderAb .slick-track {
  display: flex;
  flex-direction: row;
}
.ratting .card {
  border-radius: 20px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 4px;
  width: 600px;
  min-height: 300px;
  display: flex;
  justify-content: start;
  transition: all 0.35s ease-in-out;
}

.ratting .card button {
  color: #000;
}

.ratting .card .card-holder {
  padding: 35px 35px 0 35px;
}

.ratting .card p {
  font-size: 18px;
  font-family: "plus_jakarta_displayregular";
  color: #13102b;
  margin-bottom: 18px;
  line-height: 30px;
}

.ratting .card .img-placeholder {
  height: 90px;
  width: 90px;
  margin-right: 20px;
  margin-bottom: 14px;
  border-radius: 50%;
}

.ratting .card .img-placeholder img {
  padding: 4px;
}

.ratting .card .orange-bg1 {
  background-color: #8edd65;
}

.ratting .card .orange-bg2 {
  background-color: #ffc845;
}

.ratting .card .orange-bg3 {
  background-color: #ff7f41;
}

.ratting .card .person .clientName {
  font-size: 22px;
  font-family: "plus_jakarta_displaybold";
  color: #13102b;
}

.ratting .card .person .designation {
  font-size: 22px;
  font-family: "plus_jakarta_displayregular";
  color: #898795;
  display: none;
}

.ratting .card.purple1 {
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #f4364c, #ff7f41);
}

.ratting .card.purple2 {
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #9962cb, #fb53ba, #ff7f41, #ffc845);
}

.ratting .card.purple3 {
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #9962cb, #840b55);
}
.slick-dots {
  position: absolute;
  bottom: -65px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 30px;
  height: 30px;
  font-family: "slick";
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  color: white;
  opacity: 0.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before {
  color: #ffa142;
  opacity: 0.75;
}

.award-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.award-images .img-fluid {
  width: 33%;
  padding: 10px;
}
.mb-140 {
  margin-bottom: 140px;
}

.top-img {
  margin-bottom: 117px;
}

.p-100 {
  padding: 100px;
}

.site-footer {
  background-color: #020e1a;
  padding-top: 100px;
  padding-bottom: 10px;
}
p.CopyRightSection {
  font-size: 12px;
  opacity: 0.3;
  margin-top: 30px;
}
.site-footer .nav .nav-item {
  display: block;
  width: 100%;
  margin-bottom: 14px;
}
.site-footer .nav h4 {
  color: #fff;
  font-family: "plus_jakarta_displayregular";
  font-size: 16px;
  font-weight: 500;
  padding: 0px;
}
.site-footer .nav .nav-item .nav-link {
  color: #ffffff9e;
  font-family: "plus_jakarta_displayregular";
  font-size: 14px;
  font-weight: 500;
  padding: 0px;
  transition: all 0.25s ease-in-out;
}
.site-footer .nav p {
  color: #ffffff9e;
  font-family: "plus_jakarta_displayregular";
  font-size: 14px;
  font-weight: 500;
  padding: 0px;
  transition: all 0.25s ease-in-out;
}
.site-footer .nav .nav-item:hover .nav-link {
  color: #ffb844 !important;
  padding-left: 5px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.priceSection nav {
  background-color: transparent !important;
}
.priceSection nav ul {
  background-color: transparent !important;
  border: 2px solid #fff;
  border-radius: 30px;
  width: max-content;
  margin: 0px auto;
  margin-bottom: 33px;
}
.priceSection nav ul li {
  margin: 0px;
}

/* .priceSection nav .nav-item {
	margin-right: auto;
	width: auto;
} */

.priceSection nav button {
  border-radius: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.nav-pills .nav-link {
  font-family: "plus_jakarta_displayregular";
  font-size: 16px;
  color: #fff;
  width: 100%;
}

.nav-pills .nav-link.active {
  background-image: linear-gradient(133deg, #8edd65, #67d2df, #5869f3);
  color: #fff;
  background-color: #5d8fec;
  border: solid 0px #ffffff;
  box-shadow: 0px 23px 19px -17px #01d7e0b8;
  transition: all 0.25s ease-in-out;
}
.priceSection .nav-pills .nav-link:hover {
  background-image: linear-gradient(133deg, #8edd65, #67d2df, #5869f3);
  color: #fff;
  background-color: #5d8fec;
  border: solid 0px #ffffff;
  box-shadow: 0px 23px 19px -17px #01d7e0b8;
  transition: all 0.25s ease-in-out;
}

.contact-modal {
  padding-top: 200px;
  overflow: hidden;
  padding-bottom: 100px;
}

.contact-modal h2 {
  font-family: "sorabold";
  font-size: 26px;
  margin-bottom: 33px;
}

.contact-modal p {
  font-family: "soraregular";
  font-size: 18px;
  max-width: 300px;
  margin: 0px;
  margin-bottom: 50px;
}

.contact-modal h4 {
  font-family: "soraregular";
  font-size: 20px;
  margin-bottom: 13px;
  margin-top: 21px;
  color: #fff;
}

.contact-modal .contactBox {
  width: 100%;
  max-width: 1000px;
  position: relative;
  height: 100%;
  margin: 0 auto;
  border: none;
  border-radius: 30px;
  padding: 70px;
  padding-top: 120px;
  background: linear-gradient(110deg, #162f5d, #604477);
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
  .contact-modal .contactBox {
    width: 90%; /* Set width to 70% for screens 1024px or less */
    padding: 50px; /* Adjust padding for better fit */
    padding-top: 80px; /* Adjust top padding for mobile */
    border-radius: 20px; /* Optionally adjust border radius */
  }
}

@media (max-width: 768px) {
  .contact-modal .contactBox {
    width: 90%; /* Further adjust width for smaller screens */
    padding: 30px; /* Further reduce padding for mobile */
    padding-top: 50px; /* Adjust top padding for mobile */
    border-radius: 15px; /* Optionally adjust border radius */
  }
}

.contact-modal .contactBox .btnGradient {
  width: 231px;
  height: 72px;
  text-align: left;
  float: left;
}

.contact-modal .contactBox:before {
  background-image: url(../images/rocket.png);
  content: "";
  width: 330px;
  height: 390px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: 67%;
  z-index: 1;
  left: -5%;
  animation: bouncing 5s infinite linear;

}

.contact-modal .contactBox:after {
  background-image: url(../images/mail.png);
  content: "";
  width: 631.21px;
  height: 408.29px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  top: -23%;
  z-index: 1;
  right: -40%;
  /* animation: blinks 4s infinite linear; */
}

.contact-modal .z-index-1 {
  z-index: 1;
}

.contact-modal .form-control {
  background-color: transparent;
  color: #ffffff80;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.contact-modal .form-submit {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 10px 0 2px;
  display: flex;
  padding: 5px 20px;
  z-index: 1;
  position: relative;
  font-size: 14px;
  min-width: 160px;
  background: linear-gradient(286deg, #ff7f41, #ffc845, #fb53ba);
  min-height: 52px;
  align-items: center;
  font-weight: 300;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  max-width: 230px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}
.contact-modal .form-submit:after {
  top: 2px;
  left: 2px;
  width: 157px;
  height: 48px;
  content: " ";
  z-index: -1;
  position: absolute;
  animation: animatedgradient 10s ease infinite alternate;
  background: #3e3358;
  border-radius: 6px;
  -webkit-animation: animatedgradient 10s ease infinite alternate;
  transition: all 0.25s ease-in-out;
}
.contact-modal .form-submit:hover {
  box-shadow: 0px 14px 23px -13px #fb5db0;
  transition: all 0.25s ease-in-out;
}
.contact-modal .form-submit:hover:after {
  background: #000;
  transition: all 0.25s ease-in-out;
}
form .form-floating > .form-control {
  background-color: transparent;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px !important;
  border: 1px solid #fff;
  height: 60px;
}

.form-floating > .form-control {
  border: 1px solid #162f5d;
  font-family: "poppinsregular";
}

form .form-floating > label {
  font-size: 12px;
  padding-top: 15px;
  color: #ffffff80;
}
form .form-floating > textarea.form-control {
  background-color: transparent;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px !important;
  border: 1px solid #fff;
  height: 160px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8e92c0;
  font-size: 12px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #8e92c0;
  font-size: 12px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
  font-size: 12px;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #8e92c0;
  font-size: 12px;
}

.form-check label {
  font-size: 12px;
  color: #fff;
  font-family: "plus_jakarta_displayregular";
  margin-bottom: 4px;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 7px;
  border-radius: 0 !important;
}

.input-group button {
  background-color: #7578a2;
  color: #fff;
  min-width: 85px;
  font-family: "poppinsregular";
  margin-bottom: 0px !important;
  border: 1px solid #fff;
  border-right: 0;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.input-group img {
  height: 100%;
}

.input-group .dropdown-menu a {
  color: #162f5d;
  padding: 5px;
  font-size: 12px;
  font-weight: normal;
}

.priceSection .nav-pills .nav-link {
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 15px;
  border-radius: 100px;
}

.basicShot1 {
  padding: 28px 50px;
  border-radius: 20px;
  border: 1px solid transparent;
  margin: 0 12px;
  color: #fff;
  margin-bottom: 60px;
  height: 300px;
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #949cbe;
  background: linear-gradient(135deg, #5869f3, #9962cb);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 15px 18px #ffffff3d;
}

.basicShot1 h4 {
  font-size: 30px;
  font-family: "ranybold";
  margin-bottom: 10px;
}

.basicShot1 h3 {
  font-size: 70px;
  font-family: "plus_jakarta_displaybold";
}
.pr-30 {
  padding-right: 30px;
}
.basicShot1 sup {
  font-size: 15px !important;
  font-family: "plus_jakarta_displaybold";
  top: -4em;
  left: 0;
}

.btn.btnshot {
  width: 100%;
  padding: 16px 14px;
  font-size: 16px;
}

.shotLinks span {
  color: #fff;
  font-size: 22px;
  font-family: "plus_jakarta_displayregular";
  margin-left: 0px;
}

.shotLinks li {
  padding-bottom: 10px;
  border-bottom: 1px solid #a5a0a3;
  margin-bottom: 10px;
}

.pricePackage .phoneDetail {
  margin-top: 72px;
}

.pricePackage .phoneDetail h3 {
  font-size: 30px;
  font-family: "ranyregular";
  color: #fff;
}

.pricePackage .phoneDetail h4 {
  font-size: 24px !important;
  font-family: "ranyregular";
  color: #fff;
}

.pricePackage .phoneDetail img {
  margin-right: 15px;
}

.bg-image {
  /* The image used */
  /* background-image: url('//photographer.jpg'); */
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mb-40 {
  margin-bottom: 40px;
}

.form-package {
  font-family: "soraregular";
  padding: 35px;
  background-color: #1e263d;
  border-radius: 10px;
}

.form-package .btn.btnGradientOne {
  opacity: 1;
}

/* .form-package form .form-floating > label {
	color: #fff;
	font-size: 16px;
	padding-top: 10px;
} */

.form-package p {
  font-size: 16px;
  margin-bottom: 0px;
}

.form-package span {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
}

.form-package .stripe {
  margin-top: 27px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #0000001f;
}

.form-package .stripe p {
  margin-bottom: 0;
}

.form-package .border {
  color: #707070;
  margin-top: 40px;
  margin-bottom: 11px;
}

.form-package input[type="text"],
.form-package input[type="email"],
.form-package input[type="number"] {
  background-color: transparent;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px !important;
  border: 1px solid #fff;
  height: 60px;
}

.StripeElement {
  background-color: transparent;
  color: #ffffff;
  font-size: 14px !important;
  border-radius: 10px !important;
  border: 1px solid #fff;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
}

.StripeElement > .__PrivateStripeElement {
  width: 100%;
}
.StripeElement .__PrivateStripeElement .ElementsApp .InputElement {
  font-size: 14px;
}
.form-package label {
  font-size: 12px;
  padding-top: 15px;
  color: #ffffff80;
}
.form-package label.inline-label {
  font-size: 14px;
  color: #fff;
  padding-top: 0px;
}
.form-package h5 {
  text-align: center;
  font-size: 15px;
  font-family: "soraregular";
  color: #fff;
  margin-top: 20px;
}

.form-package h5 img {
  margin-right: 8.5px;
}

.form-package button {
  width: 100%;
}
.innerPageTemp .AboutSection.feature img {
  height: auto;
  width: 300px;
  width: 100%;
}
.innerPageTemp .AboutSection.feature .feature-card {
  padding: 0 200px;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.simpleContentSection {
  margin-top: 50px;
}
.simpleContentSection h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
.simpleContentSection h3 {
  font-size: 22px;
  margin-bottom: 16px;
  font-family: "plus_jakarta_displayregular";
}
.simpleContentSection p {
  color: #fff;
  font-size: 16px;
  font-weight: 200;
  font-family: "soraregular", sans-serif;
  margin: 0px auto 40px;
}
.OurServiceSection img {
  max-width: 450px;
}
.OurServiceSection h1,
.OurServiceSection h4 {
  font-size: 40px;
  font-family: "plus_jakarta_displayregular";
  margin-bottom: 20px;
  line-height: 60px;
}
.OurServiceSection p {
  font-family: "soralight";
  font-size: 18px;
}
.OurServiceSection a.btn {
  margin: 0px;
}
h5.secondaryHeading {
  font-family: "ranyregular";
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 30px;
}
.width-1000 {
  max-width: 1000px;
}
.whyChooseUs .info-box {
  padding: 30px 150px;
}
.whyChooseUs .info-box img {
  max-width: 400px;
}
.whyChooseUs .info-box h4 {
  font-size: 36px;
  font-family: "ranybold";
  margin-bottom: 22px;
}
.whyChooseUs .info-box p {
  font-family: "soralight";
  font-size: 20px;
  line-height: 32px;
}
.align-center {
  align-items: center;
}
.team-box {
  position: relative;
  overflow: hidden;
}
.team-box .teamDetail {
  position: absolute;
  left: 0;
  right: 0;
  width: 90%;
  background: #fff;
  bottom: -120px;
  margin: 0px auto;
  text-align: center;
  padding: 20px 10px;
  transition: all 0.25s ease-in-out;
}
.team-box:hover .teamDetail {
  bottom: 0px;
  transition: all 0.25s ease-in-out;
}
.team-box .teamDetail h6 {
  font-size: 18px;
  font-family: "ranybold";
  margin-bottom: 0px;
  color: #020e1a;
}
.team-box .teamDetail p {
  color: #020e1aa8;
  font-size: 14px;
  font-weight: 200;
  font-family: "soraregular", sans-serif;
  margin: 0px;
}
.PortfolioSectionScreen .portfolioItem .row > div:nth-child(even) {
  margin-top: 60px;
}
.PortfolioSectionScreen .portfolioItem .video {
  position: relative;
}
.PortfolioSectionScreen .portfolioItem .video:after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #000;
  opacity: 0;
  position: absolute;
  z-index: 0;
  transition: all 0.25s ease-in-out;
}
.PortfolioSectionScreen .portfolioItem .video:hover:after {
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
}
.PortfolioSectionScreen .portfolioItem .video:hover .middle {
  opacity: 1;
  cursor: pointer;
  z-index: 1;
  transition: all 0.25s ease-in-out;
}
ul.shotLinks {
  list-style: none;
  padding: 0px;
  margin: 0px;
  max-width: 350px;
}
#tsparticles {
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: -5;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}

.mb-28 {
  margin-bottom: 28px;
}

.PortfolioSectionScreen .VideoSection .video {
  height: auto;
}

.PortfolioSectionScreen h3 {
  color: #fff;
  text-align: center;
  font-family: "ranyregular";
  font-size: 28px;
}

.video.Portfolio .leftArrowSlide {
  transform: translate(-120%, -50%);
  position: absolute;
  z-index: 1;
  top: 50%;
  cursor: pointer;
}

.video.Portfolio .rightArrowSlide {
  right: -84px;
  position: absolute;
  z-index: 1;
  top: 36%;
  cursor: pointer;
}

.video.Portfolio .btn-close {
  padding: 15px 20px;
  right: -77px;
  top: -12%;
}

.video.Portfolio .modal-content {
  padding: 100px;
  background-color: #00000096;
  backdrop-filter: blur(10px);
}

.video.Portfolio h4 {
  font-size: 25px;
  font-family: "ranybold";
  color: #fff;
}

.video.Portfolio p {
  font-size: 18px;
  font-family: "soralight";
}

.portfolio-custom h2,
.portfolio-header h2 {
  font-family: "ranyregular";
  font-size: 56px;
  text-align: center;
}

.portfolio-custom p,
.portfolio-header p {
  font-family: "soralight";
  font-size: 32px;
}

.portfolio-body .nav-link {
  background-color: transparent;
  font-family: "ranybold";
  font-size: 44px;
  opacity: 0.5;
  margin-bottom: 40px;
  text-align: left;
}

.portfolio-body .nav-link.active {
  color: #fff;
  background-color: transparent;
  background-image: none;
  opacity: 1;
}
#QueryForm .form-control.error {
  background-color: #f9cece !important;
  border: 1px solid #f71515 !important;
}
#QueryForm .errorMsg {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #f71515;
}
#QueryForm span.successMsg {
  color: #50d8ab;
  text-align: center;
}
#contactUsForm .form-control.error {
  background-color: #220909 !important;
  border: 1px solid #f71515 !important;
}
#contactUsForm .errorMsg {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #f71515;
}
#contactUsForm span.successMsg {
  color: #50d8ab;
  text-align: center;
}
.thankyouPage .text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thankyouPage .text-center img {
  width: 200px;
  margin-bottom: -40px;
}
.error404Page .text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error404Page .text-center img {
  width: 300px;
  margin-bottom: -40px;
}
#talkteam .form-control:focus {
  color: #162f5d !important;
}
.btn:disabled,
button:disabled.btnGradientOne {
  pointer-events: none;
  opacity: 0.65;
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(286deg, #26304e, #26304e, #26304e);
}
.btn:disabled span,
button:disabled.btnGradientOne span {
  color: #fff;
  opacity: 0.5;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btnGradientOne span {
  color: #000;
  opacity: 1;
}
img.logo-footer {
  max-width: 170px;
}
.spinner {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #f39102;
  width: 25px;
  height: 25px;
  margin: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.badges {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}
.badges .bark-widget img {
  max-width: 80px;
  margin: 0 10px 0 0px;
}
.badges .trustpilot-widget img {
  max-width: 130px;
  margin: 0 10px 0 10px;
}
.OurServiceSection strong {
  padding-bottom: 5px;
  border-bottom: 5px solid #ffff;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Animation Css */
.rotate {
  animation: rotation 4s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.blinks {
  animation: blinks 4s infinite linear;
}
@keyframes blinks {
  50% {
    opacity: 0;
  }
}

.gradentAnimation {
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bouncing {
  animation: bouncing 5s infinite linear;
}
@keyframes bouncing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.animborder {
  animation: animborder 2s linear infinite;
}
@keyframes animborder {
  0% {
    left: 0px;
  }
  100% {
    left: 100%;
  }
}

/* PromoModal */
#promoModal .btn-close {
  box-sizing: content-box;
  width: unset;
  height: unset;
  padding: 6px 10px;
  color: #fff;
  border: 0;
  border-radius: 50px;
  opacity: 0.5;
  background: #000;
  position: absolute;
  top: 10px;
  right: 10px;
}

#promoModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 17px;
  outline: 0;
  overflow: hidden;
  max-height: 680px;
}

#promoModal .modal-content h2 {
  font-size: 24px;
  font-family: "plus_jakarta_displaybold";
  margin: 40px 0 12px 0px;
  color: #161616;
}

#promoModal .modal-content .img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
}

#promoModal .formContainer {
  padding: 0px 40px;
}

#promoModal .modal-content p {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #8b8b8b;
  margin: 0px auto 20px;
}

#promoModal .modal-content form .form-floating > .form-control {
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-family: "plus_jakarta_displayregular";
  border-radius: 8px !important;
  border: 1px solid #162f5d;
}

#promoModal .modal-content form .form-floating > label {
  font-size: 12px;
  padding-top: 16px;
  font-family: "plus_jakarta_displayregular";
  color: #0e1216;
}

#promoModal .modal-content form .form-floating {
  margin-bottom: 5px;
}

#promoModal h4 {
  font-size: 20px;
  font-family: "plus_jakarta_displaybold";
  margin: 15px 0 6px 0px;
  color: rgba(0, 0, 0, 0.6);
}

#promoModal .form-check label {
  font-family: "plus_jakarta_displayregular";
  font-size: 12px;
  font-weight: 400;
  color: #545454;
}

#promoModal .checkListItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

#promoModal .checkListItems .form-check {
  width: 50%;
}

#promoModal button.btnfull {
  font-size: 16px;
  border-radius: 5px;
  background-color: #feeab4;
  color: #fb972f;
  min-height: 44px !important;
  transition: all 0.25s ease-in-out;
  border: 1px solid #fb972f;
  display: flex !important;
  align-content: center;
  justify-content: center;
}

#promoModal button.btnfull:hover {
  background-color: #fdbb09;
  background-image: linear-gradient(#fdbb09, #fb972f);
  color: #fff;
  box-shadow: 2px 7px 17px #fb972f;
  box-shadow: 0px 24px 17px -14px #e8922570;
  transition: all 0.25s ease-in-out;
}

#promoModal button.btnfull i {
  margin-top: 6px;
}
#promoModal .form-control:focus {
  color: #162f5d !important;
}
.ratting .card .card-holder img {
  width: 100%;
}
.trustpilot-widget {
  background: #fff;
  padding: 10px 0px;
  border-radius: 5px;
}
