@media (max-width: 767px) {
	.portfolio-header.mt-100 {
		margin-top: 0px;
	}
	.portfolio-header.mt-100 .btnGradientOne {
		margin-bottom: 50px;
	}
	.portfolio-body.mt-200 {
		margin-top: 50px;
	}
	.portfolio-custom h2,
	.portfolio-header h2 {
		font-family: 'ranyregular';
		font-size: 20px;
		text-align: center;
	}
	.portfolio-custom p,
	.portfolio-header p {
		font-family: 'soralight';
		font-size: 16px;
	}
	.portfolio-custom img,
	.portfolio-header img {
		max-width: 100%;
	}
	.VideoSection.portfolio-custom {
		margin-top: 50px;
	}
	.portfolio-body .mt-100 {
		margin-top: 50px !important;
	}
	.portfolio-body img {
		max-width: 200px;
	}
	.portfolio-body .nav-link {
		font-size: 11px;
		margin-bottom: 0px;
	}
	.PortfolioSectionScreen .VideoSection {
		margin-bottom: 50px;
		margin-top: 50px;
	}
	.PortfolioSectionScreen .VideoSection .mt-200 {
		margin-top: 50px;
	}
	.video.Portfolio .leftArrowSlide {
		left: 12%;
		position: absolute;
		z-index: 1;
		top: 50%;
		cursor: pointer;
		height: 50px;
	}
	.video.Portfolio .rightArrowSlide {
		right: -1%;
		position: absolute;
		z-index: 1;
		top: 45%;
		cursor: pointer;
		height: 50px;
	}
	.video.Portfolio .btn-close {
		padding: 6px 10px;
		right: 0%;
		top: -10%;
	}
	.video.Portfolio .modal-content {
		padding: 5px;
	}
	.video.Portfolio .modal-content .mb-45 {
		margin-bottom: 5px;
	}
	.video.Portfolio h4 {
		font-size: 12px;
		font-family: 'ranybold';
		color: #fff;
	}
	.video.Portfolio p {
		font-size: 12px;
		font-family: 'soralight';
	}
	.ChooseSection.feature p {
		font-size: 12px;
		line-height: 24px;
	}
	.ChooseSection .container::before {
		content: none;
	}
	.ChooseSection .container::after {
		content: none;
	}
	.btn.btnshot {
		width: 200px;
		height: 67px;
		margin-bottom: 50px !important;
		font-size: 24px;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: auto;
	}
	.form-package label {
		font-size: 12px;
	}
	.form-package form .form-floating > label {
		color: #fff;
		font-size: 12px;
	}
	.pricePackage .phoneDetail h3 {
		font-size: 23px;
		padding-bottom: 20px !important;
	}
	.pricePackage .phoneDetail img {
		height: 24px;
	}
	.pricePackage .phoneDetail h4 {
		font-size: 15px !important;
	}
	.shotLinks li {
		padding-bottom: 8px;
	}
	.pricePackage .phoneDetail h3 {
		font-size: 23px;
		color: #fff;
		padding-bottom: 20px !important;
	}
	.price-container {
		margin-right: 10px !important;
	}
	.form-package .stripe p {
		margin-bottom: 0;
		padding: 0;
	}
	.p-100 {
		padding: 5px;
	}
	.contact-modal .container {
		padding: 15px;
	}
	.contact-modal {
		padding-top: 135px;
		overflow: hidden;
		padding-bottom: 100px;
	}
	h1,
	h2 {
		font-size: 25px;
		padding: 5px;
	}

	.banner {
		min-height: 500px;
	}
	.banner:after,
	.banner:before {
		content: none;
	}
	.videoIframe .container::before {
		content: none;
	}
	.contact-modal .container:before,
	.contact-modal .container:after {
		content: none;
	}
	.priceSection nav .nav-item button {
		padding: 5px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.VideoSection {
		margin-bottom: 100px;
	}
	.site-footer .nav .nav-item .nav-link {
		font-size: 12px;
	}
	.site-footer .nav .nav-item .logo-footer {
		height: 40px;
	}
	.dropdown-menu.serviceDropdown {
		width: 100%;
		padding: 0;
		padding-left: 20px;
	}
	.dropdown-menu.serviceDropdown .d-flex {
		flex-direction: column;
	}

	.navbar-dark {
		background-color: #020e1a !important;
	}
	.h3Bar {
		font-size: 25px;
	}
	.h3Bar span:before,
	.h3Bar span:after {
		content: none;
	}
	h4 {
		font-size: 24px;
		font-family: 'plus_jakarta_displayregular';
	}
	h3 {
		font-size: 38px;
		font-family: 'plus_jakarta_displaybold';
	}
	.price-container ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		font-weight: 400;
		margin-top: -6px;
	}
	.price-container ul li span {
		font-family: 'soralight';
		font-size: 12px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
	.navbar-dark .navbar-brand {
		color: #fff;
		margin-left: 12px;
	}
	.navbar-toggler {
		padding: 0.25rem 0.75rem;
		font-size: 1.25rem;
		line-height: 1;
		background-color: #b11c51;
		border: 1px solid #b11c51;
		border-radius: 0.25rem;
		transition: box-shadow 0.15s ease-in-out;
	}
	.navbar-dark .navbar-toggler {
		color: rgb(255 255 255);
		border-color: #b11c51;
	}
	header #navbarSupportedContent {
		overflow: scroll;
		height: calc(100vh - 60px);
		background-color: #020e1a !important;
		padding-bottom: 20px;
	}
	header .navbar-expand-lg .container {
		background-color: #020e1a !important;
	}
	header .navbar-expand-lg .navbar-nav {
		align-items: self-start;
		margin-top: 30px;
		padding: 0 30px;
	}
	.nav-item.dropdown:hover .dropdown-menu.serviceDropdown {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}
	.dropdown-menu.serviceDropdown li {
		width: 100%;
	}
	.dropdown-menu.serviceDropdown .dropdown-item {
		font-size: 14px;
		font-family: 'plus_jakarta_displayregular';
	}
	.dropdown-menu.serviceDropdown .dropdown-item img {
		margin-right: 15px;
		width: 30px;
		height: 30px;
	}
	p {
		font-size: 16px;
		padding: 5px;
		line-height: 22px;
	}
	h1.bannerHeading {
		font-family: 'ranybold';
		color: #fff;
		font-size: 40px;
		text-align: center;
		font-weight: 900;
		line-height: 40px;
		margin-bottom: 20px;
		margin-top: 40px;
	}
	h2.subHeading {
		font-family: 'ranybold';
		color: #fff;
		font-size: 40px;
		text-align: center;
		font-weight: 900;
		line-height: 40px;
		margin-bottom: 10px;
	}
	.btnGradient {
		width: 230px;
		min-width: 230px;
	}
	.btnGradient:before {
		width: 226px;
	}
	.btnGradientOne {
		width: 230px;
	}
	.CompanySlider img {
		height: 100px;
		padding: 0 0px;
		margin-top: 30px;
	}
	h2.mainHeading {
		font-size: 30px;
		margin: 10px auto 20px;
		overflow: hidden;
		max-width: 320px;
	}
	h2.mainHeading:after {
		top: 96%;
	}
	h2.mainHeading::before {
		top: 96%;
	}
	.priceSection .tab-content .tab-pane {
		padding-top: 0px;
	}
	.PackageContainerSlider {
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Center items on larger screens */
		margin: 0 auto; /* Center the slider itself */
	}
	

	.price-container:hover {
		transform: scale(1.05); /* Scale effect on hover */
	}
	
	@media (max-width: 768px) {
		.price-container {
			flex: 1 1 100%; /* Full width on mobile */
			margin-right: 100px;
		}
	}
	
	@media (min-width: 769px) and (max-width: 1024px) {
		.price-container {
			flex: 1 1 45%; /* Two cards per row on tablet */
		}
	}
	
	@media (min-width: 1025px) {
		.price-container {
			flex: 1 1 30%; /* Three cards per row on desktop */
		}
	}
	
	.price-container {
		padding: 28px 20px;
		border-radius: 20px;
		color: #000;
		border: 1px solid transparent;
		margin: 10px 5px;
		transition: all 0.25s ease-in-out;
		color: #fff;
		width: 100%;
		height: 470px;
		background-size: contain;
		min-width: 280px;
	}
	.price-container::before {
		z-index: 0;
	}
	.price.text-center {
		margin-top: 30px;
	}
	.priceSection nav .nav-item {
		width: 100%;
	}
	.priceSection nav ul {
		background-color: #01151cde !important;
		border: 2px solid #ffffff30;
		border-radius: 25px;
		width: 80%;
		margin: 0px auto;
		margin-bottom: 33px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.priceSection .nav-pills .nav-link {
		font-size: 14px;
		height: 48px;
		padding: 10px !important;
		border-radius: 30px;
	}
	.videoIframe .iframeV {
		max-width: 90%;
	}
	.videoIframe iframe {
		min-height: 200px;
		height: 200px;
	}
	.AboutSection .container::before {
		display: none;
	}

	.AboutSection .container::after {
		display: none;
	}
	.PrtfolioGrid .grid-wrapper {
		/* display: grid;
		grid-gap: 4px 4px; */
		
	}
	.PrtfolioGrid .grid-wrapper .PrtfolioItem1 {
		grid-column: 1;
		grid-row: 1;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem2 {
		grid-column: 1;
		grid-row: 2;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem3 {
		grid-column: 2 / 4;
		grid-row: 1 / 3;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem4 {
		grid-column: 1 / 3;
		grid-row: 3;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem5 {
		grid-column: 1/3;
		grid-row: 4;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem6 {
		grid-column: 3 / 4;
		grid-row: 3;
	}

	.PrtfolioGrid .grid-wrapper .PrtfolioItem7 {
		grid-column: 3 / 4;
		grid-row: 4;
	}
	.middle {
		opacity: 0.9;
		width: 50px;
		height: 50px;
	}
	.middle img {
		width: 100%;
	}
	.AboutSection.feature .feature-card {
		height: auto;
		align-items: center;
		padding: 0 30px;
		margin: 20px 0 0px 0 !important;
	}
	.AboutSection.feature p.number {
		text-align: center !important;
	}
	.AboutSection.feature h4 {
		text-align: center !important;
		font-size: 20px;
		font-family: 'ranybold';
		margin-bottom: 10px;
	}
	.AboutSection.feature p {
		text-align: center !important;
		font-size: 16px;
	}
	.feature-card .feature-img {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.CommentsSection .sliderAb {
		margin-bottom: 70px;
		padding: 0 20px;
	}
	.sliderAb .ratting {
		margin: 0 0px;
		padding: 0 5px;
		/* width: 100%; */
	}
	.ratting .card .card-holder {
		padding: 10px;
	}
	.ratting .card {
		width: 100%;
		max-width: 320px;
		height: auto;
		max-height: 100%;
		min-height: 180px;
	}
	.ratting .card p {
		font-size: 16px;
		font-family: 'plus_jakarta_displayregular';
		color: #13102b;
		margin-bottom: 5px;
		line-height: 20px;
	}
	.ratting .card .img-placeholder {
		height: 60px;
		width: 60px;
	}
	.ratting .card .person .clientName,
	.ratting .card .person .designation {
		font-size: 14px;
	}
	.sliderAb .slick-list {
		max-width: 320px;
		margin: 0px auto;
	}
	.slick-dots {
		bottom: -60px;
		left: 0;
	}
	.awardSection .mb-140 {
		margin-bottom: 10px;
	}
	.awardSection .img-fluid.bouncing {
		max-width: 80%;
		margin: 0px auto;
		display: block;
	}
	.contact-modal .contactBox:after {
		width: 300px;
		height: 300px;
		top: -15%;
		right: -18%;
	}
	.contact-modal .contactBox {
		padding: 20px;
		padding-top: 120px;
	}
	.contact-modal .contactBox:before {
		width: 140px;
		height: 140px;
		top: 96%;
		z-index: 1;
		background-size: contain;
		left: 50%;
		animation: bouncing 5s infinite linear;
	}
	.site-footer {
		padding-top: 50px;
		padding-bottom: 10px;
	}
	.site-footer .nav .nav-item {
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}
	.site-footer .nav {
		margin-bottom: 20px;
		padding: 0 30px;
	}
	#talkteam .formContainer {
		padding: 0px 20px;
	}
	#talkteam .modal-content h2 {
		font-size: 24px;
		font-family: 'plus_jakarta_displaybold';
		margin: 20px 0 0px 0px;
		color: #161616;
	}
		#promoModal .formContainer {
			padding: 0px 20px;
		}
	
		#promoModal .modal-content h2 {
			font-size: 24px;
			font-family: 'plus_jakarta_displaybold';
			margin: 20px 0 0px 0px;
			color: #161616;
		}
	.OurServiceSection h4 {
		font-size: 26px;
		margin-bottom: 10px;
		line-height: 30px;
	}
	.OurServiceSection img {
		width: 100%;
		margin-top: 50px;
	}
	h5.secondaryHeading {
		font-family: 'ranyregular';
		text-transform: uppercase;
		font-size: 30px;
		margin-bottom: 30px;
	}
	.InnerPageTitle p.text-center {
		padding: 0 20px;
	}
	.innerPageTemp .AboutSection.feature .feature-card {
		padding: 0 10px;
	}
	.innerPageTemp .AboutSection.feature img {
		height: auto;
		width: 200px;
		max-width: 100%;
	}
	.feature-card p.text-start {
		margin-bottom: 10px;
	}
	.innerPageTemp .AboutSection.feature-card .feature-img img {
		height: auto;
		width: 200px;
		max-width: 100%;
		margin-bottom: 60px;
	}
	.reverseOnMobile .row {
		flex-direction: column-reverse;
	}
	.CommentsSection {
		height: auto;
	}
	.whyChooseUs .info-box {
		padding: 20px 20px;
	}
	.whyChooseUs .info-box h4 {
		font-size: 26px;
		font-family: 'ranybold';
		margin-bottom: 6px;
		text-align: center !important;
	}
	.whyChooseUs .info-box p {
		font-family: 'soralight';
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 10px;
		text-align: center !important;
	}
	.whyChooseUs .info-box img {
		width: 100%;
		margin-bottom: 20px;
	}
	.team-box {
		position: relative;
		overflow: hidden;
		padding: 10px 10px;
		width: 50%;
	}
	.PortfolioSectionScreen .portfolioItem .row > div:nth-child(even) {
		margin-top: 0px;
	}
	.PortfolioSectionScreen .portfolioItem .video {
		position: relative;
		margin-top: 40px;
	}
	.PortfolioSectionScreen h3 {
		font-size: 18px;
	}
	.tab-content.mt-100 {
		margin-top: 0px !important;
	}
	.basicShot1 {
		padding: 20px 20px;
		border-radius: 20px;
		border: 1px solid transparent;
		margin: 0 12px;
		margin-bottom: 10px;
		height: 200px;
	}

	.basicShot1 h4 {
		font-size: 24px;
		margin-bottom: 10px;
	}

	.basicShot1 h3 {
		font-size: 50px;
	}
	.basicShot1 sup {
		top: -3em;
		left: 0;
	}
	.shotLinks span {
		color: #fff;
		font-size: 16px;
	}
	ul.shotLinks {
		padding: 0 30px;
	}
	.pricePackage .phoneDetail {
		margin-top: 40px;
	}
	.pricePackage .phoneDetail h3 {
		font-size: 22px;
		color: #fff;
		padding-bottom: 20px !important;
		text-align: center;
	}
	.row.phoneDetail .align-items-center {
		align-items: center !important;
		justify-content: center;
	}
	.row.priceBar {
		display: flex;
		flex-direction: revert;
		flex-wrap: nowrap;
	}
	.row.priceBar > div:first-child {
		width: 60%;
	}
	.row.priceBar > div:last-child {
		width: 40%;
	}
	.row.priceBar p {
		padding: 0;
	}
		.PrtfolioGrid .grid-wrapper .box {
					width: 50%;
						grid-column: unset !important;
						grid-row: unset !important;
		}
}
